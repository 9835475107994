<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <p>
          <ValidationProvider v-slot="{errors, classes}"
                              :name="label"
                              :rules="rules">
            <sb-input :label="label"
                      :error="classes.invalid">
              <v-autocomplete v-model="selectedValue"
                              :loading="$wait.is('searchClientsLoading')"
                              :items="clientResults"
                              :search-input.sync="searchClientString"
                              :filter="customSearchClientFilter"
                              :readonly="readonly"
                              :clearable="!readonly"
                              :error-messages="errors"
                              :error="classes.invalid"
                              item-text="id"
                              return-object
                              cache-items
                              hide-details
                              hide-selected
                              dense
                              outlined
                              @input="clientSelectedChange">
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for Client by
                      <strong>Client Id</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{item}">
                  <span>{{ item.id }}</span>
                </template>

                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.id" />
                    <v-list-item-subtitle v-text="item.name" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <span class="field-details"
                    :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
            </sb-input>
          </validationprovider>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapWaitingActions } from 'vue-wait';
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';

export default {
  components: {
    ValidationProvider,
  },
  mixins: [inputBase],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },
    label: {
      type: String,
      default: 'Client Number',
    },
    clientDefaultValue: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      searchClientString: null,
      selectedClientResult: null,
      clientResults: [],
    };
  },

  computed: {
    selectedValue: {
      get() {
        if (this.clientDefaultValue) {
          return {
            id: this.clientDefaultValue,
          };
        }
        return this.selectedClientResult;
      },
      set(newValue) {
        this.selectedClientResult = newValue;
      },
    },
  },

  watch: {
    async searchClientString(value) {
      // eslint-disable-next-line
      value && value !== this.selectedClientResult && (await this.getClientsDetails(value.toUpperCase()));
    },
    clientDefaultValue: {
      async handler(value) {
        if (value) {
          this.clientResults = [{ id: value }];
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('clients', ['searchClients']),

    ...mapWaitingActions('clients', {
      searchClients: 'searchClientsLoading',
    }),

    customSearchClientFilter(item, queryText) {
      const clientId = item.id ? item.id.toLowerCase() : '';
      const clientName = item.name ? item.name.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return clientId.includes(searchText) || clientName.includes(searchText);
    },

    async clientSelectedChange() {
      this.$emit('client', this.selectedClientResult);
    },

    async getClientsDetails(value) {
      if (value.length > 3) {
        this.$emit('client-loading', true);
        const tempClientResults = await this.searchClients(value);
        if (tempClientResults !== null) {
          this.clientResults = tempClientResults;
        }
        this.$emit('client-loading', false);
      }
    },
  },
};
</script>
