<template>
  <div>
    <v-row :justify="centered ? 'center' : undefined">
      <v-col cols="12">
        <p>
          <ValidationProvider v-slot="{errors, classes}"
                              :name="label"
                              :rules="rules">
            <sb-input :label="label"
                      :error="classes.invalid">
              <v-autocomplete :ref="refName"
                              v-model="selectedValue"
                              :loading="$wait.is('getClientVehiclesLoading')"
                              :items="tempVehicleResults"
                              :filter="customSearchVehicleFilter"
                              :readonly="readonly"
                              :clearable="!readonly"
                              :error-messages="errors"
                              :error="classes.invalid"
                              :disabled="disabled"
                              item-text="id"
                              return-object
                              cache-items
                              hide-details
                              hide-selected
                              dense
                              outlined
                              @input="selectedChange">
                <template #no-data>
                  <v-list-item>
                    <v-list-item-title>
                      Search for Vehicle by
                      <strong>Vehicle Number</strong>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template #selection="{item}">
                  <span>{{ item.vehicleNumber }}</span>
                </template>

                <template #item="{item}">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.vehicleNumber" />
                    <v-list-item-subtitle v-text="item.registrationNumber" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <span class="field-details"
                    :class="{'error--text': errors.length > 0}">{{ errors[0] }}</span>
            </sb-input>
          </validationprovider>
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ValidationProvider } from 'vee-validate';
import inputBase from '@/mixins/inputBase';
import { mapWaitingActions } from 'vue-wait';

export default {
  components: {
    ValidationProvider,
  },
  mixins: [inputBase],
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: [Object, String],
      default: undefined,
    },
    label: {
      type: String,
      default: 'vehicle Number',
    },
    vehicleDefaultValue: {
      type: String,
      default: undefined,
    },
    billingCentreId: {
      type: String,
      default: undefined,
    },
    clientId: {
      type: String,
      default: undefined,
    },
    refName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      searchVehicleString: null,
      selectedVehicleResult: null,
      vehicleResults: [],
      tempVehicleResults: [],
    };
  },

  computed: {
    selectedValue: {
      get() {
        if (this.vehicleDefaultValue) {
          return this.tempVehicleResults.find((data) => data.vehicleNumber === this.vehicleDefaultValue);
        }
        return this.selectedVehicleResult;
      },
      set(newValue) {
        this.selectedVehicleResult = newValue;
      },
    },
  },

  watch: {
    clientId: {
      handler(value) {
        this.searchVehicleString = value;
      },
      immediate: true,
    },
    searchVehicleString: {
      async handler(value) {
        if (value && value !== this.selectedVehicleResult) {
          await this.getVehicleDetails(value);
        }
        if (value === undefined && this.vehicleDefaultValue === undefined) {
          this.$refs[this.refName].cachedItems = [];
          this.selectedValue = null;
        }
      },
      immediate: true,
    },
    vehicleDefaultValue: {
      handler(value) {
        if (value) {
          this.searchVehicleString = this.clientId;
        }
      },
      immediate: true,
    },
    billingCentreId: {
      handler(value) {
        // 0000 => Contractual Client that does not have billing centre
        if (value && this.clientId !== undefined && value === '0000') {
          this.tempVehicleResults = this.vehicleResults.filter((element) => element.client.costCentre === value);
          if (this.$refs[this.refName]) this.$refs[this.refName].cachedItems = this.tempVehicleResults;
        } else {
          this.tempVehicleResults = this.vehicleResults;
          if (this.$refs[this.refName]) this.$refs[this.refName].cachedItems = this.tempVehicleResults;
        }
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions('vehicles', ['getClientVehicles']),
    ...mapWaitingActions('vehicles', {
      getClientVehicles: 'getClientVehiclesLoading',
    }),
    customSearchVehicleFilter(item, queryText) {
      const vehicleNumber = item.vehicleNumber ? item.vehicleNumber.toLowerCase() : '';
      const registrationNumber = item.registrationNumber ? item.registrationNumber.toLowerCase() : '';
      const searchText = queryText.toLowerCase();

      return vehicleNumber.includes(searchText) || registrationNumber.includes(searchText);
    },

    async selectedChange() {
      this.$emit('vehicle', this.selectedVehicleResult);
    },
    async getVehicleDetails(value) {
      this.vehicleResults = await this.getClientVehicles(value);
      this.tempVehicleResults = this.vehicleResults;
    },
  },
};
</script>
